import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import styled from 'astroturf';
import { NavLink } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSliderLogic } from '../../service/hooks/useSliderLogic';
import { scrollToElement } from '../../service/utils/html';
import { LeftSliderButton, RightSliderButton } from '../elements/buttons/SliderButton';

export type TabsItem = {
	label: string;
	value?: string | number;
	path?: string;
};

interface Props {
	tabs: TabsItem[];
	onChange?: (value: string | number) => void;
	active?: string | number;
	uppercase?: boolean;
	hideBackground?: boolean;
}
export const Tabs: FC<Props> = ({ uppercase, tabs, onChange, active, hideBackground }) => {
	const activeElement = useRef<HTMLDivElement>();

	const container = useRef<HTMLElement>(null);

	const { showButtons, leftButtonDisabled, rightButtonDisabled, onScroll, onScrollToLeft, onScrollToRight } =
		useSliderLogic(container, [tabs]);

	const [debouncedScrollToElement] = useDebouncedCallback(scrollToElement, 500);

	useEffect(() => {
		if (container.current && activeElement.current) {
			debouncedScrollToElement(container.current, activeElement.current);
		}
	}, [active]);

	const onChangeActive = useCallback(
		(value: string | number) => () => {
			onChange && onChange(value);
		},
		[onChange],
	);
	return (
		<Wrapper>
			<TabsWrapper beforeButtons={showButtons}>
				<Container onScroll={onScroll} onEndScroll={onScroll} innerRef={container}>
					{tabs.map((tab, index) => (
						<TabWrapper
							key={index}
							ref={(element) => {
								if (tab.value === active && element) {
									activeElement.current = element;
								}
							}}
						>
							{tab.path ? (
								<Tab to={tab.path} uppercase={uppercase} hideBackground={hideBackground}>
									{tab.label}
								</Tab>
							) : (
								<Tab
									as="div"
									uppercase={uppercase}
									hideBackground={hideBackground}
									pointer={!!tab.value}
									className={`${tab.value === active ? 'active' : ''}`}
									onClick={(tab.value && onChangeActive(tab.value)) || undefined}
								>
									{tab.label}
								</Tab>
							)}
						</TabWrapper>
					))}
				</Container>
			</TabsWrapper>
			<Buttons>
				{showButtons && (
					<LeftStyledSliderButton black={true} disabled={leftButtonDisabled} onClick={onScrollToLeft} />
				)}
				{showButtons && (
					<RightStyledSliderButton black={true} disabled={rightButtonDisabled} onClick={onScrollToRight} />
				)}
				{showButtons && <Shadow hidden={rightButtonDisabled} />}
			</Buttons>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	align-items: flex-end;
`;

const TabsWrapper = styled.div<{ beforeButtons?: boolean }>`
	width: 100%;
	border-bottom: solid 1px #e0e0e0;
	min-width: 0;
	position: relative;
	&.beforeButtons {
		margin-right: 15px;
	}
`;

const TabWrapper = styled.div`
	&:not(:last-child) {
		padding-right: 30px;
		margin-right: 10px;
	}
`;

const Tab = styled(NavLink, { allowAs: true })<{ pointer?: boolean; uppercase?: boolean; hideBackground?: boolean }>`
	@import '../../styles/constants.scss';
	@import '../../styles/mixins.scss';
	color: #757575;
	font-size: 12px;
	font-weight: bold;
	line-height: 19px;
	padding-bottom: 4px;
	padding-top: 4px;
	position: relative;
	white-space: nowrap;
	display: inline-block;
	&.pointer {
		cursor: pointer;
	}
	&.uppercase {
		text-transform: uppercase;
	}
	&:after {
		transition: 0.5s;
		content: '';
		background: transparent;
		width: 100%;
		bottom: 0;
		height: 1px;
		left: 0;
		display: block;
		position: absolute;
	}
	&:global(.active) {
		&.hideBackground {
			background: none;
		}
		background: $color-gray;
		color: $color-black;
		&:after {
			background: $color-black;
		}
	}
`;

const Container = styled(ScrollContainer)`
	width: 100%;
	display: flex;
	margin-bottom: -1px;
	align-items: flex-end;
`;

const Buttons = styled.div`
	display: flex;
	position: relative;
	margin-left: auto;
	align-items: center;
`;

const Shadow = styled.div<{ hidden?: boolean }>`
	pointer-events: none;
	position: absolute;
	width: 59px;
	height: 27px;
	background-image: linear-gradient(90deg, rgba(#f5f5f5, 0) 6%, rgba(#f5f5f5, 1) 67%);
	left: -59px;
	transition: opacity 0.25s;
	&.hidden {
		opacity: 0;
	}
`;

const LeftStyledSliderButton = styled(LeftSliderButton)`
	border-right: none;
	height: 27px;
`;

const RightStyledSliderButton = styled(RightSliderButton)`
	height: 27px;
`;
