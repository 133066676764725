import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import styled, { css } from 'astroturf';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { TextEllipsisWithTooltip, ConfirmationModal } from '@ea/common';
import { ListItem } from '@ea/common/src/service/typings/interface';
import { TooltipPopup } from '../../popups/TooltipPopup';
import { GridPopup, POPUP_APPEAR_TIME } from '../../popups/GridPopup';
import { ReactComponent as EditIcon } from '../../../images/icons/edit-column.svg';
import { ReactComponent as HideIcon } from '../../../images/icons/hide-column.svg';
import { ReactComponent as RemoveIcon } from '../../../images/icons/remove-column.svg';
import { ReactComponent as SortIcon } from '../../../images/icons/sort-column.svg';
import { GridSortDirection } from '../../../service/typings/grid';

const styles = css`
	@import '@ea/common/dist/constants.scss';
	.tooltip {
		filter: blur(0.000001px);
		-webkit-font-smoothing: antialiased;
		z-index: $z-index-tooltip !important;
	}
`;

interface IProps {
	value: string;
	tooltip?: string;
	popupIcon?: HTMLDivElement;
	popupLabel?: string;
	onEdit?: () => void;
	onHide?: () => void;
	onRemove?: () => Promise<any>;
	custom?: boolean;
	alterable?: boolean;
	sortable?: boolean;
	sortingDirection?: GridSortDirection;
	sortingActive?: boolean;
	width?: number;
	onSort?: () => void;
}

export const HeaderContent: FC<IProps> = ({
	value,
	onEdit,
	onRemove,
	onHide,
	custom,
	alterable,
	sortingActive,
	sortingDirection,
	sortable,
	width,
	onSort,
	tooltip,
	popupIcon,
	popupLabel,
}) => {
	const dropdownIcon = useRef<HTMLDivElement>(null);
	const [popupOpen, setPopupOpen] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);

	const { t } = useTranslation();

	const closeModal = useCallback(() => {
		setModalOpen(false);
	}, [setModalOpen]);

	const togglePopup = useCallback(() => {
		setPopupOpen((state) => !state);
	}, [setPopupOpen]);

	const closePopup = useCallback(() => {
		setPopupOpen(false);
	}, [setPopupOpen]);

	const onSelect = useCallback(
		(item: ListItem) => {
			setTimeout(() => {
				if (item.value === 'rename' && onEdit) {
					onEdit();
				}
				if (item.value === 'hide' && onHide) {
					onHide();
				}
				if (item.value === 'remove' && onRemove) {
					setModalOpen(true);
				}
			}, POPUP_APPEAR_TIME / 2);

			closePopup();
		},
		[closePopup, onHide, onEdit, setModalOpen],
	);

	const onClick = useCallback(() => {
		if (sortable && onSort) {
			onSort();
		}
	}, [onSort, sortable]);

	const listItems = useMemo(() => {
		const items = [
			{
				label: t('actions.renameTitle'),
				value: 'rename',
				icon: <EditIcon />,
			},
			{
				label: t('actions.hide'),
				value: 'hide',
				icon: <HideIcon />,
			},
			{
				label: t('actions.delete'),
				value: 'remove',
				icon: <RemoveIcon />,
			},
		];
		if (custom) {
			return items;
		} else {
			return [items[1]];
		}
	}, [custom, t]);

	const columnName = value ? value : 'Без имени';

	return (
		<Tooltip
			classes={{
				tooltip: styles.tooltip,
				popper: styles.tooltip,
			}}
			title={tooltip || ''}
			enterDelay={1000}
			placement={'bottom'}
		>
			<Wrapper>
				<TextWrapper onClick={onClick} sortable={sortable}>
					{value ? (
						<>
							<Text disabledTooltip={!!tooltip} width={width}>
								{columnName}
							</Text>
						</>
					) : (
						<UnnamedText disabledTooltip={!!tooltip} width={width}>
							{columnName}
						</UnnamedText>
					)}
					{popupLabel && (
						<TooltipIconWrapper>
							<TooltipPopup text={popupLabel}>{popupIcon}</TooltipPopup>
						</TooltipIconWrapper>
					)}
				</TextWrapper>
				{sortable && onSort && sortingActive ? (
					<StyledSortIcon descension={sortingDirection === 'desc'} onClick={onSort} />
				) : (
					alterable && <DropdownIcon open={popupOpen} onClick={togglePopup} ref={dropdownIcon} />
				)}
				<GridPopup
					open={popupOpen}
					onSelect={onSelect}
					onClose={closePopup}
					anchorEl={dropdownIcon.current}
					offset="0px,19px"
					items={listItems}
				/>
				<ConfirmationModal
					open={modalOpen}
					onClose={closeModal}
					title={t('grid.removeColumnModal.title')}
					question={t('grid.removeColumnModal.question', { name: columnName })}
					explanation={t('grid.removeColumnModal.explanation')}
					cancelText={t('grid.removeColumnModal.cancel')}
					submitText={t('grid.removeColumnModal.submit')}
					onSubmit={onRemove}
				/>
			</Wrapper>
		</Tooltip>
	);
};

const StyledSortIcon = styled(SortIcon)<{ descension?: boolean }>`
	cursor: pointer;
	flex-shrink: 0;
	margin-right: 6px;
	margin-left: 8px;
	&.descension {
		transform: rotate(180deg);
	}
`;

const DropdownIcon = styled.div<{ open: boolean }>`
	display: block;
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	position: relative;
	cursor: pointer;
	&:before {
		content: '';
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 5px 0 5px;
		border-color: #008bc6 transparent transparent transparent;
		transition: 0.1s;
	}
	&.open {
		&:before {
			transform: translate(-50%, -50%) rotate(180deg);
		}
	}
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
`;

const TextWrapper = styled.div<{ sortable?: boolean }>`
	display: flex;
	align-items: center;
	min-width: 0;
	width: 100%;
	&.sortable {
		cursor: pointer;
	}
`;

const Text = styled(TextEllipsisWithTooltip)`
	text-overflow: ellipsis;
	font-size: 12px;
	font-weight: bold;
	display: inline-block;
	width: auto;
`;

const UnnamedText = styled(Text)`
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
	opacity: 0.5;
`;

const TooltipIconWrapper = styled.div`
	margin-left: 5px;
	display: flex;
	align-items: center;
`;
