import React from 'react';
import styled from 'astroturf';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { Checkbox } from './Checkbox';

interface LabeledCheckboxProps extends CheckboxProps {
	label?: string;
}

export const LabeledCheckbox = ({ label, className, ...props }: LabeledCheckboxProps) => {
	return (
		<Label className={className}>
			<StyledCheckbox {...props} />
			<Text>{label}</Text>
		</Label>
	);
};

const Text = styled.span`
	margin-left: 4px;
	font-size: 12px;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0;
`;

const StyledCheckbox = styled(Checkbox)`
	margin-left: -6px;
`;

const Label = styled.label`
	display: flex;
	align-items: center;
	height: 32px;
	cursor: pointer;
`;
