import React, { useCallback, useEffect, useState } from 'react';
import styled from 'astroturf';
import { useDebouncedCallback } from 'use-debounce';
import { ColumnEvent, ICellRendererParams } from 'ag-grid-community';
import { GridColumn, GridSort, GridSortDirection } from '../../../service/typings/grid';
import { HeaderContent } from './HeaderContent';
import { HeaderInput } from './HeaderInput';

interface IProps extends ICellRendererParams {
	displayName: string;
	tooltip?: string;
	popupLabel?: string;
	popupIcon?: HTMLDivElement;
	onColumnEdited?: (field: string, data: Partial<GridColumn>) => void;
	onColumnRemoved?: (field: string) => void;
	onColumnSorted?: (sort: GridSort) => void;
}

export const HeaderWrapper = (props: IProps) => {
	const { displayName, onColumnEdited, onColumnRemoved, onColumnSorted, column, tooltip, popupIcon, popupLabel } =
		props;

	const colDef = column.getColDef() as GridColumn;

	const [width, setWidth] = useState<number>(0);

	const onRename = useCallback(
		(value: string) => {
			if (onColumnEdited && colDef.field) {
				onColumnEdited(colDef.field, {
					headerName: value,
					editing: false,
				});
			}
		},
		[colDef.field, onColumnEdited],
	);

	const onEdit = useCallback(() => {
		if (onColumnEdited && colDef.field) {
			onColumnEdited(colDef.field, {
				editing: true,
			});
		}
	}, [colDef.field, onColumnEdited]);

	const onHide = useCallback(() => {
		if (onColumnEdited && colDef.field) {
			onColumnEdited(colDef.field, {
				hidden: true,
			});
		}
	}, [colDef.field, onColumnEdited]);

	const onRemove = useCallback(() => {
		return new Promise((resolve, reject) => {
			if (onColumnRemoved && colDef.field) {
				resolve(onColumnRemoved(colDef.field));
			} else {
				reject();
			}
		});
	}, [colDef.field, onColumnRemoved]);

	const onSort = useCallback(() => {
		if (onColumnSorted) {
			if (!colDef.sortingActive) {
				onColumnSorted({ field: colDef.field, order: colDef.sortingDirection as GridSortDirection });
			} else {
				if (colDef.sortingDirection === 'asc') {
					onColumnSorted({ field: colDef.field, order: colDef.sortingDirection === 'asc' ? 'desc' : 'asc' });
				} else {
					onColumnSorted({});
				}
			}
		}
	}, [colDef.field, colDef.sortingDirection, onColumnSorted, colDef]);

	const [onChangeWidth] = useDebouncedCallback(({ column }: ColumnEvent) => {
		if (column) {
			setWidth(column.getActualWidth());
		}
	}, 250);

	useEffect(() => {
		column.addEventListener('widthChanged', onChangeWidth);
		return () => {
			column.removeEventListener('widthChanged', onChangeWidth);
		};
	}, []);

	return (
		<Wrapper alterable={colDef.alterable} first={colDef.first}>
			{colDef.editing ? (
				<HeaderInput value={displayName} onRename={onRename} />
			) : (
				<HeaderContent
					onEdit={onEdit}
					onRemove={onRemove}
					onSort={onSort}
					onHide={onHide}
					tooltip={tooltip}
					popupIcon={popupIcon}
					popupLabel={popupLabel}
					value={displayName}
					sortable={colDef.sortable}
					custom={colDef.custom}
					alterable={colDef.alterable}
					sortingDirection={colDef.sortingDirection}
					sortingActive={colDef.sortingActive}
					width={width}
				/>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div<{ alterable?: boolean; first?: boolean }>`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	cursor: auto;
	padding-left: 12px;
	padding-right: 12px;
	&.alterable {
		padding-right: 6px;
	}
	&.first {
		padding-left: 18px;
	}
`;
