import React from 'react';
import styled from 'astroturf';
import { LabeledCheckbox } from '@ea/common';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { AbstractRenderer } from './AbstractRenderer';

interface Params {
	onChange?: CheckboxProps['onChange'];
	label?: string;
	value?: boolean;
}

export class LabeledCheckboxRenderer extends AbstractRenderer<Params> {
	className = 'ag-grid-with-padding-cell';

	render({ onChange, value, label }: Params) {
		return (
			<CheckboxWrapper>
				<StyledLabeledCheckbox checked={value} onChange={onChange} label={label} />
			</CheckboxWrapper>
		);
	}
}

const CheckboxWrapper = styled.div`
	white-space: nowrap;
	height: 100%;
`;

const StyledLabeledCheckbox = styled(LabeledCheckbox)`
	width: 100%;
`;
