import { isFunction } from 'lodash-es';

export function wrapPromise(
	promise: (() => Promise<unknown>) | Promise<unknown>,
	before?: () => void,
	after?: () => void,
) {
	before?.();
	return Promise.resolve(isFunction(promise) ? promise() : promise).finally(after);
}
