import React, { FC, ReactNode, useRef, useState } from 'react';
import styled from 'astroturf';
import { wrapPromise } from '@ea/common';
import { UploadFileButton } from '@ea/common/src/components/elements/buttons/UploadFileButton';
import { AbstractRenderer } from './AbstractRenderer';

export class UploadFileRenderer extends AbstractRenderer<Props> {
	className = 'ag-grid-all-centered-cell';

	public render(params: Props) {
		return <Renderer {...params} />;
	}
}

interface Props {
	onUpload?: (file: File) => Promise<unknown>;
	label?: string;
	icon?: ReactNode;
	getLink?: () => string;
	value?: {
		name: string;
		id: number;
	};
}

const Renderer: FC<Props> = ({ icon, label, onUpload, value, getLink }) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const [uploading, setUploading] = useState(false);

	const onOpenFileDialog = () => {
		if (inputRef.current && !uploading) {
			inputRef.current.click();
		}
	};

	const onSelectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		event.persist();
		const file = event.target.files?.[0];
		if (file && onUpload) {
			wrapPromise(
				onUpload(file),
				() => setUploading(true),
				() => setUploading(false),
			);
		}
		event.target.value = '';
	};

	return (
		<Wrapper disabled={uploading}>
			{value ? (
				<a download={true} target="_blank" href={getLink?.()} rel="noreferrer">
					<UploadFileButton>{value.name}</UploadFileButton>
				</a>
			) : (
				<>
					<UploadFileButton onClick={onOpenFileDialog} icon={icon}>
						{label}
					</UploadFileButton>
					<HiddenInput ref={inputRef} type={'file'} onChange={onSelectFile} />
				</>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div<{ disabled?: boolean }>`
	min-height: 100%;
	min-width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	&.disabled {
		opacity: 0.7;
	}
`;

const HiddenInput = styled.input`
	display: none;
	height: 0;
	width: 0;
`;
