import React, { FC } from 'react';
import styled, { css } from 'astroturf';
import { Tooltip } from '@material-ui/core';

interface IProps {
	text?: string;
}

const styles = css`
	.tooltip {
		background: #ffffff !important;
		color: black !important;
		border-radius: 6px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	}
`;

export const TooltipPopup: FC<IProps> = ({ text, children }) => {
	return (
		<Tooltip
			classes={{
				tooltip: styles.tooltip,
			}}
			leaveTouchDelay={300}
			title={
				<Content>
					<Text>{text}</Text>
				</Content>
			}
		>
			<Wrapper>{children}</Wrapper>
		</Tooltip>
	);
};

const Content = styled.div`
	padding: 17px 17px 20px 13px;
	font-size: 12px;
`;
const Text = styled.div`
	margin-bottom: 12px;
`;

const Wrapper = styled.span`
	display: inline-flex;
	align-items: center;
`;
