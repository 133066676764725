import React, { useCallback } from 'react';
import styled from 'astroturf';
import { ReactComponent as ChevronIcon } from '../../../images/icons/ui/chevron.svg';
import { ReactComponent as ClearIcon } from '../../../images/icons/actions/cancel.svg';
import { LongText } from '../../service/LongText';
import { LoadingIcon } from '../indicators/LoadingIcon';

interface Props {
	value?: string;
	className?: string;
	placeholder?: string;
	open?: boolean;
	disabled?: boolean;
	onClick?: () => void;
	onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
	error?: string;
	multiline?: boolean;
	touched?: boolean;
	loading?: boolean;
	active?: boolean;
	onClear?: () => void;
	clearable?: boolean;
	border?: boolean;
}

// eslint-disable-next-line react/display-name
export const SimpleDropdown = React.forwardRef<HTMLDivElement, Props>(
	(
		{
			placeholder,
			value,
			onClear,
			loading,
			open,
			active,
			onClick,
			onBlur,
			disabled,
			className,
			touched,
			error,
			multiline,
			clearable,
			border,
		},
		ref,
	) => {
		const onWrapperClick = useCallback(() => {
			if (onClick && !disabled) {
				onClick();
			}
		}, [onClick, disabled]);

		const content = value ? value : <Placeholder>{placeholder}</Placeholder>;
		const onClearHandler = useCallback(
			(event: React.MouseEvent<SVGElement>) => {
				event.stopPropagation();
				event.preventDefault();
				onClear && onClear();
			},
			[onClear],
		);

		return (
			<Wrapper
				border={border}
				active={active}
				onClick={onWrapperClick}
				onBlur={onBlur}
				disabled={disabled}
				ref={ref}
				className={className}
				invalid={Boolean(error && touched)}
				multiline={multiline}
				showOnClear={clearable}
			>
				{multiline ? (
					<MultilineText>{content}</MultilineText>
				) : (
					<LongText text={value || placeholder}>{content}</LongText>
				)}
				<ClearWrap>
					<StyledClearIcon onClick={onClearHandler} />
				</ClearWrap>
				<IconWrap>
					<PositionedIcon defaultIcon={<StyledChevronIcon rotated={open} />} loading={loading} />
				</IconWrap>
			</Wrapper>
		);
	},
);

const IconWrap = styled.div<{ showOnClear?: boolean }>`
	position: absolute;
	right: 8px;
	height: 16px;
	width: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ClearWrap = styled.div`
	position: absolute;
	right: 25px;
	height: 16px;
	width: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	visibility: hidden;
	transition: visibility 0.25s, opacity 0.25s;
`;

const PositionedIcon = styled(LoadingIcon)<{ hiddenHover?: boolean }>``;

const StyledClearIcon = styled(ClearIcon)`
	height: 8px;
	width: 8px;
`;

const Wrapper = styled.div<{
	disabled?: boolean;
	border?: boolean;
	invalid?: boolean;
	active?: boolean;
	showOnClear?: boolean;
	multiline?: boolean;
}>`
	@import '../../../styles/constants.scss';
	height: 32px;
	background: white;
	font-size: 12px;
	padding: 0px 16px;
	padding-right: 32px;
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	user-select: none;
	color: rgba(0, 0, 0, 0.87);
	&.border {
		border: solid 1px #ededed;
	}

	&.showOnClear {
		padding-right: 42px;
		&:hover {
			${ClearWrap} {
				visibility: visible;
				opacity: 1;
			}
		}
	}
	&.disabled {
		cursor: not-allowed;
		user-select: auto;
	}
	&.invalid {
		border: solid 1px $color-red;
	}

	&.multiline {
		height: auto;
		padding-top: 6px;
		padding-bottom: 6px;
		align-items: flex-start;
	}

	&.active {
		border: solid 1px #008ac6;
	}
`;

const StyledChevronIcon = styled(ChevronIcon)<{ rotated?: boolean }>`
	transition: 0.1s;
	transform: rotate(0deg);
	&.rotated {
		transform: rotate(180deg);
	}
`;

const MultilineText = styled.span``;

const Placeholder = styled.span``;
