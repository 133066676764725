import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { ICellRenderer } from 'ag-grid-community';

export abstract class AbstractRenderer<Params = any> implements ICellRenderer {
	parentElement!: HTMLElement;
	className?: string;
	tag?: string;

	public abstract render(params: Params): ReactElement;

	public init(params: Params) {
		this.createParentElement();
		if (this.parentElement) {
			ReactDOM.render(this.render(params), this.parentElement);
		}
	}

	public getGui() {
		return this.parentElement;
	}

	public refresh(params: Params) {
		if (this.parentElement) {
			ReactDOM.render(this.render(params), this.parentElement);
		}
		return true;
	}

	public destroy() {
		if (this.parentElement) {
			ReactDOM.unmountComponentAtNode(this.parentElement);
		}
	}

	private createParentElement() {
		this.parentElement = document.createElement(this.tag || 'div');
		if (this.className) {
			this.parentElement.className = this.className;
		}
	}
}
