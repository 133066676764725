import React, { CSSProperties, FC, MouseEvent, ReactNode } from 'react';
import styled from 'astroturf';

interface IProps {
	className?: string;
	disabled?: boolean;
	onClick?: (e: MouseEvent) => void;
	style?: CSSProperties;
	icon?: ReactNode;
}

export const UploadFileButton: FC<IProps> = ({ onClick, disabled = false, className = '', style, icon, children }) => {
	return (
		<Button className={className} onClick={onClick} style={style}>
			{Boolean(icon) && (
				<IconWrapper disabled={disabled} needMargin={!!children}>
					{icon}
				</IconWrapper>
			)}
			{children}
		</Button>
	);
};

const IconWrapper = styled.span<{ needMargin?: boolean; disabled?: boolean }>`
	display: inline-block;
	&.disabled {
		svg {
			fill: #aaa;
		}
	}
	&.needMargin {
		margin-right: 8px;
	}
`;

const Button = styled.label`
	@import '../../../styles/constants.scss';
	padding: 6px 22px;
	color: $color-blue;
	font-size: 12px;
	line-height: 9px;
	display: flex;
	align-items: center;
	cursor: pointer;
	text-align: center;
`;
