import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'astroturf';
import { useTranslation } from 'react-i18next';
import { TextButton } from '../../buttons/TextButton';
import { useOnEnter } from '../../../../service/hooks/useOnEnter';

interface IProps {
	onSubmit?: (text: string) => unknown;
	label?: string;
	initialValue: string;
}

export const EditSection: FC<IProps> = ({ onSubmit, label, initialValue }) => {
	const { t } = useTranslation();

	const [value, setValue] = useState(initialValue);

	const input = useRef<HTMLInputElement>(null);

	const onSubmitInternal = () => {
		onSubmit?.(value.trim());
	};

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
	};

	const onEnter = useOnEnter(() => {
		onSubmitInternal();
	}, [onSubmit]);

	useEffect(() => {
		input.current?.focus();
	}, []);

	return (
		<Wrapper>
			<InputWrapper>
				<Input ref={input} value={value} onChange={onChange} onKeyPress={onEnter} />
				<SaveButton onClick={onSubmitInternal}>{label || t('common.actions.save')}</SaveButton>
			</InputWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	@import '../../../../styles/constants.scss';
	color: $color-blue;
	font-size: 12px;
`;

const InputWrapper = styled.div`
	height: 24px;
	border: solid 1px #f5f5f5;
	display: flex;
	align-items: center;
	margin: 6px 9px 5px 12px;
`;

const Input = styled.input`
	border: none;
	outline: 0;
	min-width: 0;
	text-overflow: ellipsis;
	padding-left: 8px;
	font-size: 12px;
	width: 100%;
`;

const SaveButton = styled(TextButton)`
	font-weight: normal !important;
	width: 85px;
`;
