import React, { memo, FC, useState } from 'react';
import styled from 'astroturf';

interface IProps {
	onClick?: () => void;
}

export const IconWrapper: FC<IProps> = memo(({ onClick, children }) => {
	const [disabled, setDisabled] = useState(false);

	const handleClick = async (event: React.MouseEvent<any>) => {
		event.preventDefault();
		event.stopPropagation();
		if (!disabled) {
			setDisabled(true);
			Promise.resolve(onClick?.()).finally(() => {
				setDisabled(false);
			});
		}
	};
	return (
		<Wrap disabled={disabled} onClick={handleClick}>
			{children}
		</Wrap>
	);
});

const Wrap = styled.div<{ disabled: boolean }>`
	padding: 0px;
	display: inline-flex;
	align-items: center;
	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;
